.btn {
    @apply flex items-center justify-center font-medium bg-white;
    font-size: 15px;
    padding: 8px 16px;
    border: 1px solid #bcc8d2;
    border-radius: 10px;
    transition: all 300ms ease-in;
    outline: none;
    &--pagination {
        &.active {
            background-color: #595fed;
            color: #fff;
            border: 1px solid #595fed;
        }
    }
    &--primary {
        @apply bg-primary text-white;
        border: 1px solid transparent;
        &:hover {
            background-color: #4146d1;
        }
        &:focus {
            background: #4146d1;
            box-shadow: 0px 2px 2px #cdcaf9;
        }
    }
    &--secondary {
        @apply bg-secondary text-black;
        border: 1px solid transparent;
        &:hover {
            background-color: #f1bc1a;
        }
        &:focus {
            background: #f1bc1a;
            box-shadow: 0px 2px 2px rgba(253, 234, 177, 0.67);
        }
    }
    &--black {
        @apply bg-black text-white;
        border: 1px solid transparent;
        &:hover {
            box-shadow: 0px 2px 2px #cdcaf9;
        }
    }
    &--white {
        @apply relative text-primary-brand bg-white;
        border: 1px solid #bcc8d2;
        &:hover {
            background-color: #f4faff;
        }
        &:focus {
            box-shadow: 0px 2px 2px #cdcaf9;
        }
    }
    &--transparent {
        @apply relative text-light-grey2 bg-white;
        border: 0;
    }
    &--lg {
        padding: 11px 22px;
        @apply text-base;
    }
    &--sm {
        padding: 6px 16px;
    }
    &--xs {
        padding: 2px 16px;
    }
    &--disable {
        opacity: 0.4;
        pointer-events: none;
        cursor: not-allowed;
    }
}
