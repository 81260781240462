.secondary-header {
    @apply relative flex items-center;
    border-bottom: 1px solid rgba(123, 141, 158, 0.3);
    &.secondary-header1 {
        z-index: -1;
    }
    &__nav {
        @apply flex items-center;
        &__item {
            color: #7b8d9e;
            @apply font-medium cursor-pointer;
            font-size: 15px;
            padding: 7px 14px;
            transition: all 300ms linear;
            &:not(:last-child) {
                margin-right: 6px;
            }
            &:hover,
            &.active {
                @apply text-primary-brand;
                background: rgba(89, 95, 237, 0.17);
                border-radius: 10px;
                transition: all 300ms linear;
            }
        }
    }
}

.icon-list-view,
.icon-grid-view {
    @apply relative w-6 h-6 flex items-center justify-center bg-transparent border-transparent rounded-md cursor-pointer;
    transition: all 300ms ease-out;
    &.active {
        // @apply bg-white border border-grey-300;
        svg #active-color {
            fill: #595fed;
        }
    }
}
