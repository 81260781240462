.mobile-preparedoc {
    &-wrapper {
        @apply w-full h-screen overflow-hidden;
        background-color: #e4ecf3;
        .signature-audit-table {
            white-space: nowrap;
        }
        .modal--signature-audit {
            .modal__body {
                padding-right: 0;
                .pr-only {
                    padding-right: 35px;
                }
            }
        }
    }
    &__header {
        @apply w-full flex items-center justify-between;
        padding: 14px 12px;
        height: 50px;
        background: #eef5fa;
        box-shadow: 0px 1px 2px rgba(107, 122, 136, 0.19);
    }
    &__footer {
        @apply w-full flex items-center gap-2 bg-white absolute bottom-0 left-0;
        padding: 10px 12px;
        height: 54px;
        box-shadow: 0px 18px 50px rgba(184, 202, 225, 0.64);
    }
    &__main {
        @apply relative w-full;
        height: calc(100vh - 104px);
    }
    &__page-navigation {
        @apply flex flex-col absolute left-0 h-full opacity-0;
        width: 90px;
        background: #eff4f8;
        box-shadow: 1px 0px 4px #dbe3ea;
        transform: translateX(-100%);
        transition: all 300ms cubic-bezier(0.4, 0.8, 0.11, 0.88);
        z-index: 1;
        &.open {
            @apply opacity-100;
            transform: translateX(0);
        }
    }
    &__page-thumbnail {
        @apply w-full bg-white;
        height: 78px;
        border: 1px solid #e6e3dc;
        border-radius: 5px;
        &-wrapper {
            @apply h-full overflow-y-auto px-3;
        }
        &.active {
            border: 2px solid #27ae60;
        }
        &:not(:last-child) {
            margin-bottom: 10px;
        }
        > * {
            @apply w-full h-full;
        }
    }
    &__page-wrapper {
        @apply flex flex-col w-full bg-white;
        height: 92%;
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    }
    &__page-container {
        @apply pb-4 pl-4 overflow-auto;
    }
    &__pdf-page-wrapper {
        @apply h-full overflow-auto;
    }
    &__zoom-wrapper {
        @apply flex items-center justify-center gap-1;
        filter: drop-shadow(0px 18px 50px rgba(184, 202, 225, 0.64));
    }
    &__zoom-list {
        @apply flex items-center justify-center text-white;
        height: 30px;
        padding: 0 10px;
        border: 1px solid #dbe3ea;
        background-color: rgba(60, 58, 89, 0.75);
        border-radius: 7px;
    }
    &__elem-container {
        overflow-x: auto;
        @apply pr-4;
    }
    &__elem-list {
        @apply flex items-center justify-between bg-white gap-3;
        padding: 3px 6px;
        border: 1px solid #dbe3ea;
        border-radius: 10px;
        &-wrapper {
            @apply flex gap-2;
            // overflow-x: overlay;
        }
        &__color {
            @apply inline-flex w-4 h-4;
            background: #27ae60;
            border-radius: 5px;
            &--green {
                background: #eb5757;
            }
            &--yellow {
                background: #f2c94c;
            }
        }
        &__check-icon {
            @apply invisible opacity-0;
            transition: all 300ms ease-out;
        }
        &.done &__check-icon {
            @apply visible opacity-100;
        }
    }
}
