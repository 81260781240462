.table-csv-preview {
    thead {
        tr {
            background-color: rgba(211, 220, 227, 0.25);
            td {
                @apply text-sm text-light-grey1 font-medium p-2;
                &:first-child {
                    @apply pl-4;
                }
                &:last-child {
                    @apply pr-4 text-right;
                }
                white-space: nowrap;
            }
        }
    }
    tbody {
        tr {
            border-bottom: 1px solid #eff2f7;
            &.error-row {
                background-color: rgba(#eb5757, 0.5);
            }
        }
        td {
            @apply text-sm font-medium py-3 px-2;
            width: 20%;
            &:first-child {
                @apply pl-4;
            }
            &:last-child {
                @apply pr-4 text-right;
            }
            &.error {
                background-color: rgba(#eb5757, 0.3);
            }
        }
    }
}
