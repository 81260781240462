.input-field {
    @apply relative w-full;
    font-size: 15px;
    padding: 12px;
    background: #f1f6fa;
    border: 1px solid #dbe3ea;
    border-radius: 10px;
    transition: all 300ms ease-in;
    outline: none;
    &--otp {
        width: 70px;
        height: 66px;
        text-align: center;
    }
    &-label {
        @apply block;
        font-size: 15px;
        font-weight: 500;
        line-height: 19px;
        margin: 5px 0px;
    }
    &:focus {
        @apply bg-white;
        border: 1px solid #595fed;
        box-shadow: 0px 4px 4px #dee3e8;
        border-radius: 10px;
        mix-blend-mode: normal;
    }
    &--sm {
        padding: 8px 12px;
    }
    &--xs {
        padding: 5px 12px;
    }
    &.error {
        border: 1px solid #eb5757;
    }
    &--search {
        background: #f1f6fa url("../../images/icon-search.svg") no-repeat right
            10px center;
        padding-right: 40px;
    }
    &--icon-copy {
        background: #f1f6fa url("../../images/icon-copy.svg") no-repeat right
            10px top 10px;
        padding-right: 40px !important;
    }
    &--no-border {
        padding: 0;
        background: transparent;
        border: 0;
        border-radius: 0;
        outline: none;
        &:focus {
            @apply bg-transparent;
            border: 0;
            box-shadow: none;
            border-radius: 0;
            mix-blend-mode: normal;
        }
    }
    &--border-b {
        background: transparent;
        border: 0;
        padding: 0;
        border-bottom: 1px solid #b7c6d5;
        border-radius: 0;
        outline: none;
        resize: none;
        &:focus {
            border: 0;
            border-bottom: 1px solid #595fed;
            box-shadow: none;
            border-radius: 0;
        }
    }
    &--date {
        width: 145px;
        background: #f1f6fa url("../../images/icon-date-grey.svg") no-repeat
            right 10px center;
        background-size: 16px;
    }
}

.custom-select {
    @apply relative w-full truncate cursor-pointer;
    font-size: 15px;
    padding: 12px;
    padding-right: 45px;
    background: #f1f6fa;
    border: 1px solid #dbe3ea;
    border-radius: 10px;
    transition: all 300ms ease-in;
    outline: none;
    -moz-appearance: none; /* Firefox */
    -webkit-appearance: none; /* Safari and Chrome */
    appearance: none;
    background: url("../../images/icon-arrow-down.svg") no-repeat right 14px
        center;
    &:focus {
        @apply bg-white;
        border: 1px solid #595fed;
        box-shadow: 0px 4px 4px #dee3e8;
        border-radius: 10px;
        mix-blend-mode: normal;
    }
    &.no-shadow-on-focus:focus {
        box-shadow: none;
    }
    &.background-white {
        background: #fff url("../../images/icon-arrow-down.svg") no-repeat right
            14px center;
    }
    &--search {
        background: #f1f6fa url("../../images/icon-search.svg") no-repeat right
            10px center;
        padding-right: 40px;
    }
    &--sm {
        padding: 8px 12px;
        padding-right: 45px;
    }
    &.error {
        border: 1px solid #eb5757;
    }
    &--no-border {
        width: initial;
        @apply text-right;
        font-size: 14px;
        padding: 0;
        padding-right: 22px;
        box-shadow: none;
        background-size: 12px;
        border: 0;
        border-radius: 0;
        background-position: right;
        &:focus {
            @apply bg-white;
            border: 0;
            box-shadow: none;
            border-radius: 0;
        }
    }
}

.input-group {
    .input-field {
        border-radius: 0;
        &:first-child {
            border-radius: 0;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
        }
        &:last-child {
            border-radius: 0;
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
        }
        &:not(:last-child) {
            // border-bottom: 0.5px solid #dbe3ea;
        }
        &:not(:first-child) {
            // border-top: 0.5px solid #dbe3ea;
        }
    }
}
