.profile-navigation {
  display: inline-block;
  &__list {
    font-size: 15px;
    @apply font-medium bg-transparent cursor-pointer;
    padding: 8px 14px;
    transition: all 300ms ease-in-out;
    &:hover,
    &.active {
      @apply text-primary-brand;
      background: #e3e4fc;
      border-radius: 6px;
    }
    &:not(:last-child) {
      @apply mb-4;
    }
  }
}
.profile-details {
  width: 666px;
  background: #ffffff;
  border-radius: 10px;
  &__header {
    @apply flex items-center justify-between px-5 py-3 border-b border-grey-500;
  }
  &__body {
    @apply flex items-start gap-12;
    padding: 19px 40px;
    .signature-container {
      @apply flex items-center justify-center p-2;
      height: 88px;
      background: #f5f5f5;
      border-radius: 10px;
      > img {
        @apply w-full h-full object-contain;
      }
    }
    .input-field {
      width: initial;
      // width: 70%;
    }
  }
}

.avatar-wrapper {
  width: 140px;
  height: 140px;
  border-radius: 100%;
  &-pic {
    @apply w-full h-full overflow-hidden;
    border-radius: 100%;
  }
}

.profile-change-password {
  width: 460px;
  background: #ffffff;
  border-radius: 10px;
}
