.switch {
    position: relative;
    display: inline-block;
}
/* Hide the checkbox input (only needed for `:checked` property) */
.switch-input {
    display: none;
}
/* Switch */
.switch-label {
    @apply block cursor-pointer;
    width: 60px;
    height: 30px;
    // text-indent: -150%;
    // clip: rect(0 0 0 0);
    // color: transparent;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background: linear-gradient(0deg, #f0f7fc, #f0f7fc), #ffffff;
    border: 1px solid #b7c6d5;
    border-radius: 20px;
    transition: all 300ms ease;
}
.switch-label::after {
    content: "";
    display: block;
    position: absolute;
    cursor: pointer;
    top: 4px;
    left: 3px;
    width: 22px;
    height: 22px;
    border-radius: 50%;

    background: #a8b7c6;
    border: 1px solid #f7fbff;
    transition: left 0.25s ease;
}
/* When input is checked */
.switch-input:checked + .switch-label {
    @apply bg-primary;
    background: red;
    border: 1px solid transparent;
    background-color: #595FED;
    &::after {
        left: 33px;
        @apply bg-white;
    }
}

.switch {
    &--sm {
        .switch-label {
            width: 55px;
            height: 25px;
            &:after {
                top: 2px;
                width: 20px;
                height: 20px;
            }
        }
    }
}