.aside-right-section {
    @apply h-full opacity-0;
    width: 0;
    @apply px-0 pt-6;
    background-color: #f2f6f9;
    border-left: 1px solid rgba(123, 141, 158, 0.2);
    transition: all 300ms ease-out, opacity 300ms ease-out 200ms;
    &__card-item {
        padding: 18px 0 18px 18px;
        @apply bg-white;
        border: 1px solid #dee3e8;
        box-shadow: 0px 9px 9px rgba(184, 202, 225, 0.3);
        border-radius: 10px;
    }
    &.open {
        width: 258px;
        @apply px-5 opacity-100;
    }
}
