.modal {
    @apply fixed w-full h-full flex items-center justify-center top-0 left-0 right-0 bottom-0 opacity-0 invisible;
    z-index: 101;
    // display: none;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    backface-visibility: hidden;
    transition: all 0.3s;
    &:after {
        content: "";
        @apply fixed w-full h-full top-0 left-0 bottom-0 right-0;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 10;
        transition: all 0.3s;
    }
    &__container {
        @apply relative w-full h-full rounded-none bg-white z-30 overflow-y-auto opacity-0 invisible;
        padding: 35px;
        box-shadow: 0px 4px 4px #bcc8d2;
        transform: scale(0.7);
        opacity: 0;
        transition: all 0.3s;
        @screen md {
            width: 536px;
            height: initial;
            border-radius: 10px;
            max-height: calc(100% - 40px);
        }
    }
    &.open {
        @apply opacity-100 visible;
        .modal__container {
            transform: scale(1);
            opacity: 1;
            visibility: visible;
        }
    }
    &__heading {
        font-size: 22px;
        @apply font-medium;
    }
    &__sub-heading {
        font-size: 13px;
        @apply text-light-text;
    }
    &__header {
        @apply flex items-start justify-between;
    }
    &__close-icon {
        @apply cursor-pointer;
    }
    &--document-success {
        .modal {
            &__container {
                @screen md {
                    width: 540px;
                }
                padding: 0;
            }
            &__header {
                padding: 30px 30px 0;
            }
            &__body {
                padding: 10px 60px 30px;
            }
            &__footer {
                padding: 20px 80px 40px;
                background-color: #f4faff;
                border-bottom-left-radius: 10px;
                border-bottom-right-radius: 10px;
            }
        }
    }
    &--signature-audit {
        > .modal__container {
            width: 800px;
            padding: 0;
            .modal__header {
                padding: 25px 35px 10px;
                @apply sticky top-0 bg-white z-10;
            }
            .modal__body {
                padding: 5px 35px 20px;
            }
        }
    }
    &--delete-confirmation {
        .modal__container {
            width: 480px;
        }
    }
    &--view-permission {
        .view-delete-user-list {
            transition: all 300ms;
            &:hover {
                background: rgba(211, 220, 227, 0.25);
            }
            &__content {
                width: calc(100% - 40px);
            }
            &__delete-icon {
                @apply opacity-0 invisible transition-all;
            }
            &:hover & {
                &__delete-icon {
                    @apply opacity-100 visible;
                }
            }
            &:hover .view-delete-user-list__delete-icon {
                @apply opacity-100 visible;
            }
        }
    }
    &--access-code {
        &::after {
            background-color: #d1e3e3;
        }
        .modal__heading {
            font-size: 16px;
            @screen sm {
                font-size: 22px;
            }
        }
        .modal__footer {
            > .btn {
                @apply w-full;
            }
            @screen sm {
                @apply flex justify-end;
                > .btn {
                    width: initial;
                }
            }
        }
    }
}

.signature-color {
    @apply flex items-center gap-2;
    &__list {
        width: 14px;
        height: 14px;
        @apply rounded-full cursor-pointer;
        background-color: #000;
        &--blue {
            background-color: #6c63ff;
        }
        &--grey {
            background-color: #5f758a;
        }
        &.active {
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        }
    }
}

.table-signature {
    @apply w-full;
    tbody {
        tr {
            td {
                @apply relative break-all cursor-pointer;
                border: 1px solid #d2e7fa;
                padding: 25px 20px;
                transition: all 300ms ease-out;
                &:hover {
                    box-shadow: 0px 18px 50px rgba(184, 202, 225, 0.64);
                }
                &.active {
                    &::after {
                        content: "";
                        @apply w-full h-full absolute top-0 left-0 bg-white border border-primary;
                        box-shadow: 0px 18px 50px rgba(184, 202, 225, 0.64);
                        z-index: -1;
                        transition: all 300ms ease-out;
                    }
                }
            }
        }
    }
}

.signature-file-upload {
    background: rgba(255, 255, 255, 0.71);
    border: 1px dashed #595fed;
    border-radius: 10px;
    padding: 20px;
    .file-upload {
        &-wrapper {
            width: initial;
            height: initial;
            margin: 0;
            padding: 0;
        }
        &__input {
            @apply hidden;
        }
        &__label {
            @apply block;
            padding: 8px 12px;
            background: #f1f6fa;
            border: 1px solid #dbe3ea;
            border-radius: 10px;
        }
    }
}

.view-file-upload-container {
    @apply flex items-center justify-center bg-white;
    border: 1px solid #dbe3ea;
    border-radius: 10px;
    > img {
        @apply w-full h-full object-contain;
    }
}

.modal--signature-audit {
    .modal--add-signature {
        display: none;
    }
}
