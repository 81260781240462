.secondary-header {
    @apply relative flex items-center;
    border-bottom: 1px solid rgba(123, 141, 158, 0.3);
    &.secondary-header1 {
        // z-index: -1;
    }
    &__nav {
        @apply flex items-center;
        &__item {
            color: #7b8d9e;
            @apply font-medium cursor-pointer;
            font-size: 15px;
            padding: 6px 15px;
            transition: all 300ms linear;
            &:hover,
            &.active {
                @apply text-primary;
                background: rgba(89, 95, 237, 0.17);
                border-radius: 10px;
            }
        }
    }
}
