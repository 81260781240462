.main {
    height: calc(100vh - 64px);
}
.avatar {
    &-container {
        width: 76px;
        height: 46px;
        @apply flex items-center justify-center bg-white cursor-pointer;
        border: 1px solid #b7c6d5;
        border-radius: 10px;
    }
    &-wrapper {
        @apply flex items-center justify-center rounded-full overflow-hidden bg-grey-400;
    }
    &__pic {
        @apply w-full h-full object-cover overflow-hidden rounded-full bg-grey-400;
    }
}
.sigPad {
    width: 100%;
    height: 220px;
}
.error-text {
    @apply text-xs text-red;
}

.bg-shadow-blur {
    @apply relative;
    &::after {
        content: "";
        @apply w-full h-full absolute top-0 left-0;
        background: #c2cddc;
        opacity: 0.3;
        filter: blur(100px);
        z-index: -1;
    }
    &--high {
        opacity: 0.6;
    }
}

ul,
li {
    list-style: none;
}

.text-base-1 {
    font-size: 15px;
}
.text-22 {
    font-size: 22px;
}
.text-13 {
    font-size: 13px;
}
.text-11 {
    font-size: 11px;
}
.text-12 {
    font-size: 12px;
}
.text-sm {
    font-size: 14px;
}
.card-item {
    background: #ffffff;
    border: 1px solid #dbe3ea;
    border-radius: 10px;
    &--no-border {
        border: 0;
    }
}

div[data-tooltip],
.drag-and-drop-comp-list[data-tooltip] {
    &:after {
        content: attr(data-tooltip);
        width: max-content;
        position: absolute;
        @apply bg-black text-white text-13 font-medium rounded opacity-0 invisible;
        padding: 3px 10px;
        top: -30px;
        // transform: translateY(-100%);
        transition: all 300ms;
        z-index: 10;
    }
    &::before {
        content: "";
        @apply absolute w-0 h-0 bg-transparent p-0 opacity-0 invisible;
        border: 8px solid transparent;
        border-top-color: #293846;
        top: initial;
        bottom: 12px;
        left: 3px;
        right: 0;
        z-index: -1;
        transition: all 300ms;
    }
    &:hover:after,
    &:hover::before {
        @apply opacity-100 visible;
        // transform: translateY(0);
    }
    &.right[data-tooltip] {
        &::after {
            top: initial;
            left: calc(100% + 8px);
        }
        &::before {
            z-index: 1;
            left: 100%;
            transform: rotate(90deg);
            transform-origin: top;
        }
    }
    &.tooltip-pos-left[data-tooltip] {
        &::after {
            top: 0;
            right: calc(100% + 8px);
        }
        &::before {
            top: 5px;
            transform: rotate(-90deg) !important;
            right: initial;
            left: -8px;
            z-index: 1;
        }
    }
}
.tooltip-grid-view {
    &::after {
        top: 50% !important;
        transform: translate(-10px, -50%);
        right: 100% !important;
    }
    &::before {
        transform: rotate(-90deg) !important;
        top: 1px !important;
        left: -10px !important;
    }
}
.access-code-icon-wrapper[data-tooltip] {
    &::after {
        top: -4px !important;
    }
    &::before {
        top: 8px;
    }
}

.w-initial {
    width: initial;
}

.signee-list {
    @apply absolute bg-white;
    width: 150px;
    border: 1px solid #bcc8d2;
    border-radius: 10px;
    z-index: 10;
    transform: translateY(20%);
    transition: all 0.3s;
    &__list {
        @apply flex items-center p-2;
        transition: all 300ms ease-in;
    }
    &__header {
        @apply p-2;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        background: #f4f6f8;
    }
    &--sm {
        .signee-list__list {
            padding: 5px 8px;
            @apply text-sm;
            line-height: unset;
            &.text-base-1 {
                font-size: 15px !important;
            }
        }
    }
}

.signee-list {
    &--default {
        .signee-list__list:hover {
            @apply text-primary-brand;
            box-shadow: none;
            background: rgba(89, 95, 237, 0.22);
            transition: all 300ms ease-in;
        }
    }
}

.tooltip-plain {
    @apply absolute text-13 font-medium rounded;
    width: max-content;
    top: -31px;
    color: #000;
    padding: 2px 6px;
    border: 1px solid #4741dc;
    background: #e0dff9;
    z-index: 20;
}

// .tooltip-bottom {
//     @apply absolute text-13 font-medium rounded;
//     width: max-content;
//     top: 31px;
//     color: #000;
//     padding: 2px 6px;
//     border: 1px solid #4741dc;
//     background: #e0dff9;
//     z-index: 20;
// }

.btn-ribbon {
    position: absolute;
    left: -10px;
    top: 20px;
    width: 21px !important;
    height: 14px !important;
    font-size: 14px;
    background-color: #a6a9f3;
    border: 1px solid #595fed;
    border-right: 0;
    border-radius: 4px !important;
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    z-index: 99;
    cursor: pointer;
    &::after,
    &::before {
        content: "";
        position: absolute;
        height: 0;
        width: 0;
        background-color: transparent;
        padding: 0;
        border: 7px solid transparent;
        border-left-color: #a6a9f3;
        top: -1px;
        right: -13px;
        z-index: -1;
    }
    &::before {
        content: "";
        right: -14px;
        border-left-color: #595fed;
        top: -1px;
    }
}
.required:after {
    content: " *";
    color: red;
}

.team-permissions-list {
    @apply border-b pb-2 mb-2;
    border-color: rgba(123, 141, 158, 0.3);
}

.red-border {
    border: 3px dotted rgba(235, 87, 87, 0.8) !important;
}

.scroll-0 {
    scrollbar-width: none;
    &::-webkit-scrollbar {
        width: 0;
    }
}
input[type="radio"] {
    accent-color: #4146d1;
}

.upload-import-workflow {
    padding: 60px 20px;
    background: rgba(255, 255, 255, 0.71);
    border: 1px dashed #595fed;
    border-radius: 10px;
}

.import-workflow-modal {
    padding: 44px;
    width: 950px;
    height: 690px;
    background: #ffffff;
    border-radius: 10px;
    .nav-list {
        &__item {
            font-size: 15px;
            padding: 10px;
            background: #ffffff;
            box-shadow: inset 0px -1px 0px rgba(184, 202, 225, 0.3);
            &.active {
                @apply text-white;
                background: #595fed;
                box-shadow: inset 0px -1px 0px rgba(184, 202, 225, 0.3);
                border-radius: 7px;
            }
        }
    }
    &__right {
        padding: 36px;
        background: #f8fbff;
        border: 1px solid #ebe3e3;
        border-radius: 10px;
    }
}

.receiver-datepicker {
    .react-datepicker-wrapper,
    .react-datepicker__input-container {
        height: 100%;
    }
}

.txt:hover {
    text-decoration: underline;
}
