.prepare-doc {
    &__header {
        @apply relative w-full z-10;
        height: 60px;
        background: #eef5fa;
        box-shadow: 0px 1px 2px rgba(107, 122, 136, 0.19);
        .document-heading {
            width: calc(100% - 510px);
            padding: 0 10px;
            @apply text-center;
        }
    }
    &__main {
        @apply flex;
        height: calc(100vh - 60px);
        background: #e5edf4;
    }
    &__section-left {
        // width: 200px;
        // height: calc(100vh - 60px);
        // top: 60px;
        @apply flex items-start h-full;
        // z-index: 12;
    }
    &__section-right {
        width: 310px;
        @apply h-full flex-shrink-0;
        background: #eff4f8;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        .nav-list {
            padding: 10px 20px;
            border-bottom: 1px solid #c6d3de;
            background: rgba(255, 255, 255, 0.53);
            &__item {
                font-size: 15px;
                @apply text-light-text cursor-pointer;
                // transition: all 300ms linear;
                &.active {
                    @apply text-primary-brand font-medium;
                }
                &:hover {
                    @apply text-primary-brand;
                }
            }
        }
    }
    .page-thumbnail {
        &-section {
            width: 119px;
            @apply relative flex flex-col h-full;
            // padding: 10px 12px 12px 16px;
            background: #eff4f8;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        }
        &-list {
            height: 114px;
            border: 1px solid #e6e3dc;
            border-radius: 5px;
            margin-bottom: 10px;
            @apply relative cursor-pointer;
            &.active {
                border: 2px solid #4b5cf1;
            }
            > * {
                @apply w-full h-full;
                border-radius: 5px;
            }
            &-wrapper {
                @apply h-full flex-1 overflow-auto pl-4 pr-3;
            }
        }
    }
    .drag-and-drop-comp {
        &-section {
            @apply h-full;
            padding: 10px 12px 12px;
            background: #e5edf4;
            box-shadow: inset -1px 0px 0px rgba(183, 198, 213, 0.56);
        }
        &-list {
            @apply relative flex flex-col items-center justify-center gap-2 cursor-pointer;
            width: 57px;
            height: 57px;
            background: linear-gradient(
                180deg,
                #ffffff 0%,
                rgba(255, 255, 255, 0.77) 100%
            );
            border: 1px solid #c2cddc;
            box-shadow: 0px 4px 4px rgba(194, 205, 220, 0.55);
            border-radius: 7px;
            &:not(:last-child) {
                margin-bottom: 8px;
            }
        }
    }
    &__pdf-page {
        &-wrapper {
            @apply relative h-full flex-1;
            padding-bottom: 20px;

            // overflow: scroll;
            // width: calc(100vw - 510px);
            // margin-left: 200px;
            // margin-top: 124px;
            // padding: 0 20px;
            // max-width: calc(100vw - 710px);
            // margin: auto 100px !important;
            .btn--start {
                @apply flex items-center justify-center font-medium;
                font-size: 14px;
                position: fixed;
                left: 165px;
                top: 129px;
                padding: 5px 25px;
                text-transform: uppercase;
                background-color: #595fed;
                border-radius: 4px;
                z-index: 99;
                height: 35px;
                color: #fff;
                cursor: pointer;
                // &:hover {
                //     box-shadow: none;
                //     background-color: #4146D1;
                // }
                transition: all 300ms;
            }
            .btn--ribbon {
                &::after {
                    content: "";
                    position: absolute;
                    height: 0;
                    width: 0;
                    background-color: transparent;
                    padding: 0;
                    border: 17px solid transparent;
                    border-left-color: #595fed;
                    top: 0;
                    right: -32px;
                    z-index: -1;
                }
            }
        }
        &-container {
            @apply w-full;
            mix-blend-mode: normal;

            // background-color: white;
            filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
            > canvas {
                @apply w-full;
            }
        }
        &__scroll-container {
            @apply w-full h-full;
            overflow: scroll;
            scrollbar-width: none;
            padding: 0 20px 20px;
            &::-webkit-scrollbar {
                width: 0;
            }
        }
    }
    &--receiver {
        .prepare-doc {
            &__pdf-page {
                &-wrapper {
                    // width: calc(100vw - 419px);
                    // margin-left: 119px;
                    // padding: 0 50px;
                }
            }
        }
        .magnify-wrapper {
            // width: calc(100vw - 419px);
            // left: 119px;
        }
    }
    &--preview {
        width: 100%;
        flex: 1 1 0%;
        margin: auto;
        @apply pb-10 pt-4;
        &__scroll {
            &::-webkit-scrollbar {
                width: 10px;
            }
        }
    }
    &__close-btn {
        @apply cursor-pointer opacity-75;
        transition: all 300ms;
        &:hover {
            @apply opacity-100;
        }
    }
}

.magnify {
    &-wrapper {
        @apply flex items-center justify-center gap-1 py-3;
        filter: drop-shadow(0px 18px 50px rgba(184, 202, 225, 0.64));
        // @apply fixed flex items-center justify-center gap-1 pt-3 pb-5;
        // filter: drop-shadow(0px 18px 50px rgba(184, 202, 225, 0.64));
        // background-color: #e5edf4;
        // width: calc(100vw - 510px);
        // top: 60px;
        // left: 200px;
        // z-index: 11;
    }
    &-list-item {
        @apply flex items-center justify-center bg-white;
        height: 35px;
        padding: 0 10px;
        border: 1px solid #dbe3ea;
        border-radius: 7px;
    }
}

.position-properties {
    &-wrapper {
        @apply flex items-center gap-2;
    }
    &-list {
        @apply flex items-center justify-center cursor-pointer;
        width: 34px;
        height: 34px;
        background: linear-gradient(
            180deg,
            #ffffff 0%,
            rgba(255, 255, 255, 0.77) 100%
        );
        border: 1px solid #c2cddc;
        box-shadow: 0px 2px 4px rgba(194, 205, 220, 0.55);
        border-radius: 7px;
        &__icon {
            position: relative;
            right: 2px;
        }
        &--right {
            transform: rotate(-180deg);
        }
        &--top {
            transform: rotate(90deg);
        }
        &--bottom {
            transform: rotate(-90deg);
        }
    }
}

.text-align-property {
    &-wrapper {
        @apply flex items-center justify-around gap-3;
        padding: 0.2rem 0.75rem;
        background-color: #f1f6fa;
        border: 1px solid #c2cddc;
        box-shadow: 0px 2px 4px rgba(194, 205, 220, 0.55);
        border-radius: 7px;
    }
    &__list {
        @apply bg-transparent border border-transparent cursor-pointer;
        padding: 6px;
        &.active {
            @apply border border-grey-300 bg-white rounded cursor-pointer;
        }
    }
}

.fields-attribute {
    &-wrapper {
        @apply flex flex-col gap-2;
    }
    &__list {
        @apply flex items-center justify-between bg-white p-3;
        border: 1px solid #dbe3ea;
        border-radius: 10px;
    }
    &__color {
        @apply inline-flex w-4 h-4;
        background: #27ae60;
        border-radius: 5px;
        &--green {
            background: #eb5757;
        }
        &--yellow {
            background: #f2c94c;
        }
        &--blue{
            background: #3498db;
        }
        &--dark{
            background: #34495e;
        }
    }
    &__check-icon {
        @apply invisible opacity-0;
        transition: all 300ms ease-out;
    }
    &__list.done {
        .fields-attribute__check-icon {
            @apply visible opacity-100;
        }
    }
}

.component-field-properties {
    @apply mb-2;
    &__list {
        @apply flex items-center justify-between py-3;
        border-bottom: 1px solid #cdd7e0;
        &-wrapper {
            @apply px-4;
        }
        &__label {
            font-size: 14px;
            margin-right: 10px;
        }
    }
    .input-textarea {
        &--border-b {
            border-bottom: 1px solid #b7c6d5;
            outline: none;
            resize: none;
            &:focus {
                border-bottom: 1px solid #595fed;
                box-shadow: none;
            }
        }
    }
    .switch {
        &-label {
            width: 55px;
            height: 25px;
            &:after {
                top: 2px;
                width: 20px;
                height: 20px;
            }
        }
    }
}
