.aside-section {
    width: 212px;
    @apply px-3 pt-6;
    background-color: #f2f6f9;
    border-right: 1px solid rgba(123, 141, 158, 0.2);
    .nav-list {
        padding: 9px 10px;
        margin-bottom: 5px;
        border-radius: 10px;
        @apply flex items-center gap-2 cursor-pointer;
        transition: all 300ms ease-in;
        &__item {
            font-size: 15px;
            @apply font-medium text-light-grey2;
        }
        &:hover {
            background: rgba(89, 95, 237, 0.22);
            .nav-list {
                &__item {
                    @apply text-primary-brand font-bold;
                }
                &__icon path {
                    fill: #595fed;
                }
                &__icon--stroke {
                    path {
                        stroke: #595fed;
                    }
                }
            }
        }

        &.active {
            background: #ffffff;
            box-shadow: 0px 0px 4px rgba(146, 146, 146, 0.25),
                0px 4px 4px #dbe3ea;
            .nav-list {
                &__item {
                    @apply text-primary-brand font-bold;
                }
                &__icon path {
                    fill: #595fed;
                }
                &__icon--stroke {
                    path {
                        stroke: #595fed;
                    }
                }
            }
        }
    }
    &__footer {
        width: calc(100% + 24px);
        margin-left: -12px;
        padding: 15px 12px;
        border-top: 1px solid rgba(123, 141, 158, 0.4);
    }
    .copyright-text {
        color: rgba(41, 56, 70, 0.7);
    }
    &--account {
        background-color: transparent;
        border: 0;
        padding-top: 0;
    }
}
