.table-list-view {
    border-collapse: separate;
    border-spacing: 0;
    thead {
        tr {
            th {
                @apply text-left font-medium;
                font-size: 15px;
                color: rgba(53, 68, 90, 0.6);
                padding: 14px 12px;
                white-space: nowrap;
            }
        }
    }
    tbody {
        tr {
            border: 1px solid #dbe3ea;
            border-radius: 10px;
            background: #ffffff;
            td {
                font-size: 15px;
                padding: 10px 12px;
                border-top: 1px solid #dbe3ea;
                border-bottom: 1px solid #dbe3ea;
                &:first-child {
                    @apply font-bold;
                    //   width: 45%;
                    border-left: 1px solid #dbe3ea;
                    border-top-left-radius: 10px;
                    border-bottom-left-radius: 10px;
                    > div {
                        word-break: break-all;
                    }
                }
                &:last-child {
                    border-right: 1px solid #dbe3ea;
                    border-top-right-radius: 10px;
                    border-bottom-right-radius: 10px;
                }
            }
            &:first-child {
                // width: 300px;
            }
            &:hover {
                background: #ffffff;
                box-shadow: 0px 18px 50px rgba(184, 202, 225, 0.64);
                transition: all 300ms ease-in;
            }
            .document-thumbnail {
                height: 38px;
                width: 35px;
                border: 2px solid #b7c6d5;
                border-radius: 5px;
                > {
                    @apply w-full h-full object-contain;
                }
            }
        }
    }
    &--teams {
        thead {
            tr {
                th {
                    @apply text-text-primary font-bold;
                }
            }
        }
        .thead-secondary {
            td {
                @apply text-xs;
            }
        }
    }
}
