.primary-header {
    height: 64px;
    @apply relative flex items-center bg-white px-4;
    box-shadow: 0px 1px 0px rgba(123, 141, 158, 0.17),
        0px 4px 4px rgba(210, 231, 250, 0.48);
    z-index: 1;
    .logo-wrapper {
        width: calc(212px - 16px);
        border-right: 1px solid rgba(123, 141, 158, 0.2);
    }
    .notification {
        &-icon {
            &-wrapper {
                @apply relative flex items-center justify-center cursor-pointer;
                width: 44px;
                height: 44px;
                border: 1px solid #b7c6d5;
                border-radius: 9px;
            }
        }
        &__count {
            @apply flex items-center justify-center absolute w-5 h-5 text-white;
            top: -6px;
            right: -9px;
            font-size: 10px;
            background: #eb5757;
            border-radius: 7px;
        }
    }
}

.dropdown-menu {
    @apply absolute bg-white;
    width: 338px;
    border: 1px solid #bcc8d2;
    border-radius: 10px;
    top: 50px;
    right: 0;
    z-index: 10;
    transform: translateY(20%);
    transition: all 0.3s;
    opacity: 0;
    &.open {
        transform: translateY(0);
        opacity: 1;
    }
    &__list {
        @apply flex items-center p-2;
        transition: all 300ms ease-in;
        &:hover,
        &.active {
            background: #fff;
            box-shadow: 0px 18px 50px rgba(184, 202, 225, 0.64);
        }
    }
    &__header {
        @apply p-5;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        background: #f4f6f8;
    }
    &__body {
        @apply p-5;
    }
    &__footer {
        @apply p-5 text-sm font-semibold bg-white;
        box-shadow: inset 0px 1px 0px #e2ebf2;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
    }
    &--arrow-head {
        @apply opacity-0 invisible;
        transform: translateY(25px);
        &::before,
        &::after {
            content: "";
            @apply absolute;
            border: 12px solid transparent;
            border-top-width: 20px;
            bottom: -31px;
            right: 26px;
            border-top-color: #fff;
        }
        &::before {
            border-top-color: #bcc8d2;
            bottom: -33px;
        }
        &.open {
            @apply opacity-100 visible;
            transform: translateY(0);
        }
        &.position-bottom {
            top: 40px;
            right: -38px;
            border-radius: 6px;
            &::before,
            &::after {
                content: "";
                @apply absolute;
                top: -27px;
                bottom: initial;
                border: 12px solid transparent;
                border-bottom-width: 16px;
                right: 26px;
                border-bottom-color: #fff;
            }
            &::before {
                border-bottom-color: #bcc8d2;
                top: -28px;
            }
        }
        &--right-corner {
            &::before,
            &::after {
                right: 20px;
            }
        }
    }
    &--sm {
        .dropdown-menu__list {
            padding: 5px 8px;
            @apply text-sm;
            line-height: unset;
            &.text-base-1 {
                font-size: 15px !important;
            }
        }
    }
}

.dropdown-menu {
    &--default {
        .dropdown-menu__list:hover {
            @apply text-primary-brand;
            box-shadow: none;
            background: rgba(89, 95, 237, 0.22);
            transition: all 300ms ease-in;
        }
    }
}

.signature-audit-table {
    .dropdown-menu {
        border: 1px solid #e8e8e8;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 6px;
        @apply bg-white opacity-0 invisible;
        transform: translateY(25px);
        transition: all 300ms ease-in;
        &__list:hover {
            box-shadow: none;
        }
        &.open {
            @apply opacity-100 visible;
            transform: translateY(0);
        }
    }
}
