@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://cdn.jsdelivr.net/npm/plusplusjakartasans@1.0.0/plusjakartasans.min.css");
@import "./assets/style.scss";
@import "react-datepicker/dist/react-datepicker.css";
html,
body {
    @apply text-text-primary;
    font-size: 15px;
    font-family: "Plus Jakarta Sans", sans-serif;
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}
