.file-upload-cloud {
    &-wrapper {
        width: 700px;
        height: 100%;
        @apply relative mx-auto p-8;
        &::after {
            content: "";
            @apply absolute top-0 left-0 w-full h-full;
            background: #c2cddc;
            opacity: 0.3;
            filter: blur(100px);
            z-index: -1;
        }
    }
    &-container {
        @apply px-8 pt-12 pb-6 mx-auto;
        background: rgba(255, 255, 255, 0.71);
        border: 1px dashed #595fed;
        border-radius: 10px;
    }
    &__list {
        @apply flex flex-col gap-2 cursor-pointer;
        padding: 14px 14px 6px;
        background: #ffffff;
        border: 1px solid #dee3e8;
        border-radius: 10px;
        transition: all 300ms ease-in;
        .cloud-icon {
            &-wrapper {
                @apply relative flex items-center justify-center;
                width: 136px;
                height: 90px;
                background: #e8f4ff;
                border-radius: 10px;
                &::after {
                    content: "";
                    @apply absolute opacity-0 invisible;
                    background: url("../../images/icon-color-check-circle.svg")
                        no-repeat top right;
                    width: 24px;
                    height: 24px;
                    top: -4px;
                    right: -4px;
                }
            }
        }
        .cloud-name {
            font-size: 15px;
        }
        .icon-check-circle {
            @apply absolute opacity-0 invisible;
            top: -7px;
            right: -3px;
            transition: all 300ms ease-in;
        }
        &.active {
            border: 1px solid #595fed;
            .cloud-icon-wrapper::after {
                @apply opacity-100 visible;
            }
        }
        &:hover {
            border: 1px solid #595fed;
        }
    }
}
